import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { VideoUtils } from '../../../core/utils/video.utils';
import { SoundTypeEnum } from '../../models/Sound';
import { PlaysSoundOnClickDirective } from '../../directives/plays-sound-on-click.directive';
import { Observe } from 'common';

export interface OverlayOptions {
  image: string | undefined; // base64 image or url
  top: number;
  height: number;
  left: number;
  width: number;
}

export type FacingMode = 'user' | 'environment';

@Component({
  selector: 'app-ar',
  standalone: true,
  imports: [AsyncPipe, NgIf, NgStyle, PlaysSoundOnClickDirective],
  templateUrl: './ar.component.html',
  styleUrl: './ar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArComponent implements OnDestroy, AfterViewInit {
  @Input()
  overlayOptions: OverlayOptions | null = null;

  @Input()
  cameraOrientation: FacingMode = 'user';

  @Observe('overlayOptions')
  private overlayOptions$!: Observable<OverlayOptions | undefined>;

  currentOrientation: FacingMode | undefined;

  @ViewChild('overlayCanvas', { static: true })
  overlayCanvas?: ElementRef<HTMLCanvasElement>;

  @ViewChild('processingCanvas', { static: true })
  processingCanvas?: ElementRef<HTMLCanvasElement>;

  animationFrameId: number | null = null;
  imageTaken: string | null = null;
  showLoader$ = new BehaviorSubject<boolean>(true);
  cameras: MediaDeviceInfo[] = [];
  currentCameraIndex = 0; // Default to the first camera
  @Output()
  imageTakenChange = new EventEmitter<string | null>();
  isBackCamera = false;
  protected readonly SoundTypeEnum = SoundTypeEnum;
  @ViewChild('cameraFeed', { static: true })
  private videoElement?: ElementRef<HTMLVideoElement>;
  private pngImage = new Image();

  async ngAfterViewInit(): Promise<void> {
    this.cameras = await this.getAvailableCameras();
    this.currentOrientation = this.cameraOrientation;
    this.videoElement?.nativeElement?.addEventListener('play', async () => {
      this.updateOverlay();
    });

    this.overlayOptions$.subscribe(async (options) => {
      await this.preloadAnnotationImage();
      await this.startCamera();
    });
    navigator.mediaDevices.addEventListener('devicechange', async () => {
      if (this.cameras.length < 2) {
        this.cameras = await this.getAvailableCameras();
        console.log('Updated camera list:', this.cameras);
      }
    });
  }

  // Function to update canvas drawing

  async preloadAnnotationImage() {
    return new Promise<void>((resolve) => {
      if (this.overlayOptions?.image) {
        this.pngImage.src = `${this.overlayOptions.image}`;
        this.pngImage.setAttribute('crossOrigin', 'anonymous');
        this.pngImage.onload = () => {
          resolve();
        };
        this.pngImage.onerror = () => {
          console.error('Error loading annotation image');
          resolve();
        };
      } else {
        resolve();
      }
    });
  }

  updateOverlay() {
    const annotation = this.overlayOptions;
    const draw = () => {
      if (
        this.videoElement?.nativeElement?.paused ||
        this.videoElement?.nativeElement?.ended
      )
        return;
      if (this.processingCanvas && this.overlayCanvas && this.videoElement) {
        this.animationFrameId = requestAnimationFrame(draw);

        const processingCtx =
          this.processingCanvas.nativeElement.getContext('2d');
        const overlayCtx = this.overlayCanvas.nativeElement.getContext('2d');
        const scaleFactor = 1; // Adjust as needed

        // Process video frame at reduced resolution
        if (
          this.videoElement.nativeElement.videoWidth === 0 ||
          this.videoElement.nativeElement.videoHeight === 0
        )
          return;
        this.processingCanvas.nativeElement.width =
          this.videoElement?.nativeElement?.videoWidth * scaleFactor;
        this.processingCanvas.nativeElement.height =
          this.videoElement?.nativeElement?.videoHeight * scaleFactor;

        if (
          processingCtx &&
          !this.isBackCamera &&
          this.videoElement?.nativeElement &&
          this.processingCanvas.nativeElement
        ) {
          // Set the context transformation matrix to mirror the image
          processingCtx.save(); // Save the current context state

          // Translate to the canvas width to offset the negative scaling
          processingCtx.translate(this.processingCanvas.nativeElement.width, 0);

          // Scale x by -1; this mirrors the image
          processingCtx.scale(-1, 1);

          // Draw the image mirrored
          processingCtx.drawImage(
            this.videoElement.nativeElement,
            0,
            0,
            this.processingCanvas.nativeElement.width,
            this.processingCanvas.nativeElement.height,
          );

          processingCtx.restore(); // Restore the context to its original state
        } else {
          processingCtx?.drawImage(
            this.videoElement.nativeElement,
            0,
            0,
            this.processingCanvas.nativeElement.width,
            this.processingCanvas.nativeElement.height,
          );
        }

        // Scale up and draw to overlay canvas
        this.overlayCanvas.nativeElement.width =
          this.videoElement?.nativeElement?.videoWidth;
        this.overlayCanvas.nativeElement.height =
          this.videoElement?.nativeElement?.videoHeight;
        if (
          this.overlayCanvas?.nativeElement?.width > 0 &&
          this.processingCanvas.nativeElement?.width > 0
        )
          overlayCtx?.drawImage(
            this.processingCanvas.nativeElement,
            0,
            0,
            this.overlayCanvas.nativeElement.width,
            this.overlayCanvas.nativeElement.height,
          );

        // Draw annotation
        if (annotation) {
          this.showLoader$.next(false);
          const newScaleFactor = this.overlayCanvas.nativeElement.width / 600; // Adjust based on your original reference size
          const newPngDetails = {
            top: annotation.top * newScaleFactor,
            left: annotation.left * newScaleFactor,
            width: annotation.width * newScaleFactor,
            height: annotation.height * newScaleFactor,
          };
          overlayCtx?.drawImage(
            this.pngImage,
            newPngDetails.left,
            newPngDetails.top,
            newPngDetails.width,
            newPngDetails.height,
          );
        } else {
          this.showLoader$.next(false);
        }
      }
    };
    this.videoElement?.nativeElement?.addEventListener('pause', () => {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
      }
    });
    this.animationFrameId = requestAnimationFrame(draw);
  }

  async startCamera() {
    try {
      // Request access to the user's camera
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: this.currentOrientation,
          width: { min: 900, ideal: 1200, max: 2000 },
          height: { min: 900, ideal: 1200, max: 2000 },
        },
        audio: false, // No audio for this application
      });
      console.log(stream.getVideoTracks()[0].getSettings());
      console.log(stream.getVideoTracks()[0].getCapabilities());
      // @ts-ignore
      if (stream.getTracks()[0].getCapabilities) {
        this.isBackCamera =
          stream.getTracks()[0].getCapabilities()?.facingMode?.[0] ===
          'environment';
      }

      // Assuming this.videoElement is already initialized in ngOnInit or somewhere appropriate
      if (this.videoElement) {
        this.videoElement.nativeElement.srcObject = stream;
        await this.videoElement.nativeElement.play();
        if (this.overlayCanvas?.nativeElement) {
          this.overlayCanvas.nativeElement.onanimationend = () => {
            this.overlayCanvas?.nativeElement.classList.add('flipping-in');
          };
        }
      }
    } catch (err) {
      console.error('Error accessing the camera: ', err);
    }
  }

  ngOnDestroy(): void {
    this.closeCurrentStream();
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  closeCurrentStream() {
    const stream = this.videoElement?.nativeElement?.srcObject as MediaStream;
    if (stream) {
      VideoUtils.closeActiveStreams(stream);
    }
  }

  takePicture() {
    const flashElement = document.querySelector('.flash');
    flashElement?.classList.add('shutterClick');
    if (flashElement) {
      flashElement.addEventListener('animationend', () => {
        flashElement.classList.remove('shutterClick');
        // Ensure the canvas is at the desired resolution for saving
        // This might already be set correctly based on your drawing logic
        if (this.overlayCanvas) {
          const canvas = this.overlayCanvas.nativeElement;
          // Convert the canvas to a data URL (image)
          this.imageTaken = canvas.toDataURL('image/png');
          this.imageTakenChange.next(this.imageTaken);
        }
      });
    }
  }

  async getAvailableCameras(retries = 3, delay = 1000) {
    for (let i = 0; i < retries; i++) {
      try {
        await new Promise((resolve) => setTimeout(resolve, delay));
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(
          (device) => device.kind === 'videoinput',
        );
        if (cameras.length > 0) return cameras;
      } catch (err) {
        console.error('Error listing devices: ', err);
      }
    }
    return [];
  }

  clearImage() {
    this.imageTaken = null;
    this.imageTakenChange.next(null);
  }

  async switchCamera() {
    this.overlayCanvas?.nativeElement.classList.remove('flipping-in');
    this.overlayCanvas?.nativeElement.classList.add('flipping-out');

    this.closeCurrentStream();
    if (this.currentOrientation === 'user') {
      this.currentOrientation = 'environment';
    } else {
      this.currentOrientation = 'user';
    }
    await this.startCamera();
  }
}
