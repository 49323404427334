import {
  ChangeDetectionStrategy,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'tmu-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingButtonComponent {
  @Input() loading: boolean | null = false;
  showSpinner: Subject<boolean> = new BehaviorSubject(false);

  @Input() disabled: boolean | null = false;

  timer: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading'] && changes['loading'].currentValue === true) {
      this.timer = setTimeout(() => {
        this.showSpinner.next(true);
      }, 600);
    } else {
      if (this.timer) clearTimeout(this.timer);
      this.showSpinner.next(false);
    }
  }
}
