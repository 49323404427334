import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[tmuImageFallback]',
})
export class ImageFallbackDirective implements AfterViewInit, OnDestroy {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private http: HttpClient,
  ) {}

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  @Input() appImageFallback: string | undefined;
  @Input() appImageInterim: string = 'assets/img/image-loader.svg';

  private originalImage: string | undefined;
  private originalSrcSet: string | undefined;
  private destroyed = false;

  async ngAfterViewInit(): Promise<void> {
    if (!this.originalImage) {
      this.originalImage = this.el.nativeElement.src;
      this.originalSrcSet = this.el.nativeElement.srcset;
    }
    for (let i = 0; i < 5; i++) {
      if (this.destroyed) break;
      const isAvailable = await this.checkImageAvailability(this.originalImage);
      if (isAvailable && this.originalImage) {
        this.renderer.setAttribute(
          this.el.nativeElement,
          'src',
          this.originalImage,
        );
        if (this.originalSrcSet)
          this.renderer.setAttribute(
            this.el.nativeElement,
            'srcset',
            this.originalSrcSet,
          );
        break;
      } else {
        this.renderer.setAttribute(
          this.el.nativeElement,
          'src',
          this.appImageInterim,
        );
        this.renderer.setAttribute(this.el.nativeElement, 'srcset', '');
        await new Promise((resolve) => setTimeout(resolve, 2000 * (i + 1)));
      }
    }
    if (
      this.el.nativeElement.src === this.appImageInterim &&
      this.appImageFallback
    ) {
      this.renderer.setAttribute(
        this.el.nativeElement,
        'src',
        this.appImageFallback,
      );
      this.renderer.setAttribute(this.el.nativeElement, 'srcset', '');
    }
  }

  private async checkImageAvailability(
    url: string | undefined,
  ): Promise<boolean> {
    if (!url) {
      return false;
    }
    const result$ = this.http.head(url, { observe: 'response' }).pipe(
      map((response) => response.status === 200),
      catchError((err, caught) => {
        console.error(err);
        return of(false);
      }),
    );

    return await firstValueFrom(result$);
  }
}
