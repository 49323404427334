import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input } from '@angular/core';
import { CameraService } from '../../core/services/camera.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-photo-input',
  styleUrls: ['./photo.input.component.scss'],
  templateUrl: './photo.input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhotoInputComponent),
      multi: true,
    },
  ],
})
export class PhotoInputComponent implements ControlValueAccessor {
  constructor(private cameraService: CameraService) {}

  teamImage$ = new BehaviorSubject<string | undefined>(undefined);
  defaultImage = 'assets/icons/camera.svg';
  @Input()
  padSquarePhoto = false;

  formActivated$ = new BehaviorSubject(false);

  @Input()
  size = 200;

  @Input()
  rounded = true;

  async takePhoto() {
    this.formActivated$.next(true);
    await this.cameraService.requestPermission();
  }

  async getImage(base64: string) {
    if (this.padSquarePhoto && base64) {
      const image = new Image();
      image.src = base64;
      image.crossOrigin = 'anonymous';

      await new Promise((resolve) => (image.onload = resolve));
      const dimension = Math.min(image.width, image.height);
      const offsetX = (image.width - dimension) / 2;
      const offsetY = (image.height - dimension) / 2;

      const canvas = document.createElement('canvas');
      canvas.width = dimension;
      canvas.height = dimension;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(
        image,
        offsetX,
        offsetY,
        dimension,
        dimension,
        0,
        0,
        dimension,
        dimension,
      );

      return canvas.toDataURL('image/jpeg');
    }
    return base64;
  }

  // Implement the ControlValueAccessor interface methods
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any) {
    this.teamImage$.next(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  async imageTakenChange($event: string | null) {
    const resizedPhoto = $event ? await this.getImage($event) : undefined;
    this.teamImage$.next(resizedPhoto);
    this.formActivated$.next(false);
    this.onChange(resizedPhoto);
    this.onTouched();
  }
}
