import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Team } from '@freddy/models';

@Component({
  selector: 'tmu-team-photo',
  templateUrl: './team-photo.component.html',
  styleUrls: ['./team-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamPhotoComponent {
  @Input()
  team: Team | null = null;
}
